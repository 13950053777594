export const cvcNumToName: Record<string, string> = {
  "1": "service",
  "2": "integrity",
  "3": "different",
  "4": "positive",
  "5": "passionate",
  "6": "innovation",
  "7": "humble",
  "8": "creative",
  "9": "transparent",
  "10": "learning",
};

export const cvcNameToNum: Record<string, string> = Object.entries(
  cvcNumToName
).reduce((memo, [key, value]) => {
  memo[value] = key;
  return memo;
}, {} as Record<string, string>);