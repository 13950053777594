import { type Component, onMount } from "solid-js";

type TurnstileProps = {
  callback?: (token: string | undefined) => void;
  errorCallback?: (state: boolean) => void;
};

export const Turnstile: Component<TurnstileProps> = (props) => {
  let turnstileRef: HTMLDivElement;
  const SITEKEY = "0x4AAAAAAAfSKod0jDQldGBP";

  onMount(() => {
    // @ts-expect-error
    window.onloadTurnstileCallback = function () {
      //@ts-expect-error
      turnstile?.render(turnstileRef, {
        sitekey: SITEKEY,
        theme: "light",
        callback: (token: string) => {
          if (props.callback) props.callback(token);
          if (props.errorCallback) props.errorCallback(false);
        },
      });
    };
    // @ts-expect-error
    if (window.turnstile && turnstileRef) {
      // @ts-expect-error
      window.onloadTurnstileCallback();
    }
  });

  return (
    <div
      class="mx-auto mt-3"
      ref={(ref) => (turnstileRef = ref)}
      id="#turnstile-container"
    />
  );
};

